import React, { Component, Fragment } from "react";
import HelmetMeta from "./HelmetMeta";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import ProductGallery from "./ProductGallery";
import CartFeatures from "./CartFeatures";
import {
  Button,
  Container,
  Divider,
  Embed,
  Grid,
  Header,
  Icon,
  Image,
  Modal,
  Responsive,
  Segment,
} from "semantic-ui-react";
import Pedigree from "./Pedigree";
import HedgiesTeaser from "./HedgiesTeaser";
import ProductsGrid from "./ProductsGrid";
import WaitingLists from "./WaitingLists";

class HedgieView extends Component {
  render() {
    const { eeData, product, addVariant, totalInventory } = this.props;
    const meta = [
      {
        title: eeData.title,
        titleSEO: eeData.titleSEO,
        titleOG: eeData.titleOG,
        descSEO: eeData.descSEO,
        descOG: eeData.descOG,
        imgOG: eeData.imgOG,
      },
    ];

    return (
      <div>
        <HelmetMeta meta={meta[0]} />
        <Container>
          <Grid padded="vertically">
            <Grid.Row className="hedgieDetails">
              <Grid.Column mobile={16} tablet={7} computer={7}>
                <ProductGallery images={eeData.images} />
                <Responsive as={Divider} hidden />
              </Grid.Column>
              <Grid.Column mobile={0} tablet={0} computer={1} />
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Header as="h1" size="tiny" className="hedgieSEO">
                  {ReactHtmlParser(eeData.name)} - {eeData.colors}{" "}
                  {eeData.gender} Hedgehog {eeData.forSale ? "for Sale" : ""}
                </Header>
                <Header as="h2" className="hedgieName">
                  {ReactHtmlParser(eeData.name)}
                  <Header.Subheader className={`genderTag ${eeData.gender}`}>
                    {eeData.gender}
                  </Header.Subheader>
                </Header>

                <dl className="hedgieMeta">
                  <div className="dl__item">
                    <dt>Born</dt>
                    <dd>{eeData.dob ? eeData.dob : "TBD"}</dd>
                  </div>
                  <div className="dl__item">
                    <dt>Ready to Go Home</dt>
                    <dd>
                      {eeData.ready ? eeData.ready : "TBD"}
                      <Modal
                        trigger={
                          <Button icon size="mini" circular compact>
                            <Icon name="question" />
                          </Button>
                        }
                        closeIcon
                      >
                        <Modal.Header>
                          About Ready To Go Home Dates
                        </Modal.Header>
                        <Modal.Content>
                          <Modal.Description>
                            <p>
                              Lorem, ipsum dolor sit amet consectetur
                              adipisicing elit. Id eligendi fuga nostrum harum
                              ipsa hic ea quis aperiam aut vero aliquam,
                              laudantium, maiores recusandae. Labore minima
                              recusandae et vero ipsa.
                            </p>
                          </Modal.Description>
                        </Modal.Content>
                      </Modal>
                    </dd>
                  </div>
                  <div className="dl__item">
                    <dt>Likely Color</dt>
                    <dd>
                      {eeData.colors ? eeData.colors : "TBD"}
                      <Modal
                        trigger={
                          <Button icon size="mini" circular compact>
                            <Icon name="question" />
                          </Button>
                        }
                        closeIcon
                      >
                        <Modal.Header>About Likely Colors</Modal.Header>
                        <Modal.Content>
                          <Modal.Description>
                            <p>
                              Lorem, ipsum dolor sit amet consectetur
                              adipisicing elit. Id eligendi fuga nostrum harum
                              ipsa hic ea quis aperiam aut vero aliquam,
                              laudantium, maiores recusandae. Labore minima
                              recusandae et vero ipsa.
                            </p>
                          </Modal.Description>
                        </Modal.Content>
                      </Modal>
                    </dd>
                  </div>
                </dl>

                {product &&
                (eeData.status === "open" || eeData.status === "On Hold") ? (
                  <CartFeatures
                    product={product}
                    eeStatus={eeData.status}
                    addVariant={addVariant}
                  />
                ) : null}

                {product ? (
                  <Segment inverted color="yellow" className="warning">
                    <Grid verticalAlign="middle">
                      <Grid.Row>
                        <Grid.Column
                          mobile={3}
                          tablet={5}
                          computer={5}
                          textAlign="center"
                        >
                          <Icon
                            name="exclamation"
                            circular
                            inverted
                            fitted
                            color="teal"
                            className="gradient-cool angled fluid"
                          />
                        </Grid.Column>
                        <Grid.Column mobile={13} tablet={11} computer={11}>
                          {eeData.status === "open" ? (
                            <p>
                              <strong>WARNING:</strong> Before you purchase a
                              hedgehog, please note that all purchases are
                              NON-REFUNDABLE. Please read our{" "}
                              <Link to="about/policies">Policies Page</Link>{" "}
                              before making a purchase.
                            </p>
                          ) : null}
                          {eeData.status === "On Hold" ? (
                            <p>
                              <strong>
                                This Hedgehog is on hold
                                {eeData.hold ? ` for ${eeData.hold}` : "."}
                              </strong>{" "}
                              If you attempt to purchase this hedgehog and are
                              NOT the person it is on hold for, your order WILL
                              be cancelled.
                            </p>
                          ) : null}
                          {eeData.status === "Sold - Ready for Pickup" ||
                          eeData.status === "Taken Home" ? (
                            <p>
                              <strong>
                                This Hedgehog has already been sold{" "}
                                {eeData.hold ? `to ${eeData.hold}` : null}
                              </strong>
                            </p>
                          ) : null}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                ) : null}
              </Grid.Column>
            </Grid.Row>

            {eeData.video[0].id ? (
              <Grid.Row>
                <Grid.Column mobile={16} tablet={7} computer={7}>
                  <Embed
                    id={eeData.video[0].id}
                    source={eeData.video[0].provider}
                    placeholder={eeData.video[0].thumbnail}
                  />
                </Grid.Column>
                <Grid.Column mobile={0} tablet={0} computer={1} />
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <Header as="h3" size="large" dividing>
                    Description of {ReactHtmlParser(eeData.name)}
                  </Header>
                  {ReactHtmlParser(eeData.description)}
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as="h3" size="large" textAlign="center" dividing>
                    Description of {ReactHtmlParser(eeData.name)}
                  </Header>
                  {eeData.description ? (
                    ReactHtmlParser(eeData.description)
                  ) : (
                    <p>This hedgie doesn't have a description.</p>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row>
              <Grid.Column>
                <Divider horizontal>
                  <Header as="h3" size="large" className="upper">
                    Pedigree (admin view)
                  </Header>
                </Divider>
                <Image
                  src="https://placehold.it/1920x800"
                  alt="Pedigree Chart"
                />
                <Pedigree slug={eeData.slug} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {eeData.forSale ? (
            <Fragment>
              <Divider />
              <Container style={{ padding: "2em 0" }}>
                <Header as="h3" size="large" className="upper">
                  Other Available Hedgehogs
                </Header>
                <p>The hedgehogs below are looking for new homes.</p>
                <ProductsGrid
                  handle="babies-for-sale"
                  stars={false}
                  limit={4}
                  fallback="No baby hedgehogs are currently available for sale."
                />
              </Container>
              <Container textAlign="center">
                <Button as={Link} to="/hedgehogs-for-sale" size="huge">
                  See All Available Hedgehogs
                </Button>
              </Container>

              <Divider style={{ marginTop: "3em " }} />

              <Container style={{ padding: "2em 0" }}>
                <Header as="h3" size="large" className="upper">
                  Recommended Supplies
                </Header>
                {/* <p>Blah blah</p> */}
                <ProductsGrid handle="supplies" stars={true} limit={4} />
              </Container>
              <Container textAlign="center">
                <Button as={Link} to="/products" size="huge">
                  See All Hedgehog Supplies
                </Button>
              </Container>

              <Divider hidden style={{ marginTop: "3em " }} />

              <Container style={{ padding: "2em 0" }}>
                <WaitingLists />
              </Container>
            </Fragment>
          ) : null}

          <Divider hidden style={{ marginTop: "3em " }} />
        </Container>
      </div>
    );
  }
}

export default HedgieView;
