import React, { Component } from "react";
//import axios from "axios";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import ProductView from "./ProductView";
import Loader from "./Loader";

class Product extends Component {
  constructor() {
    super();
    this.state = {
      //eeData: {},
    };
  }
  //   componentDidMount() {
  //     axios
  //       .get(
  //         `http://admin.suncoasthedgehogs.com/eeData/products/${this.props.match.params.handle}`
  //       )
  //       .then((eeData) => {
  //         this.setState({
  //           eeData: eeData.data[0],
  //         });
  //       });
  //   }

  //   componentWillReceiveProps(nextProps) {
  //     if (nextProps.match.params.handle != this.props.match.params.handle) {
  //       this.setState({
  //         eeData: {},
  //       });
  //       axios
  //         .get(
  //           `http://admin.suncoasthedgehogs.com/eeData/products/${nextProps.match.params.handle}`
  //         )
  //         .then((eeData) => {
  //           this.setState({
  //             eeData: eeData.data[0],
  //           });
  //       });
  //     }
  //   }

  createProductQuery(handle) {
    return gql`
         query query {
         shop {
            name
            productByHandle(handle: "${handle}") {
               title
               handle
               id
               description
               descriptionHtml
               tags
               totalInventory
               productType
               options {
                  id
                  name
                  values
               }
               variants(first: 10) {
                  edges {
                     node {
                        id
                        title
                        availableForSale
                        currentlyNotInStock
                        price
                        priceV2 {
                        amount
                        }
                        selectedOptions {
                        name
                        value
                        }
                        image {
                        altText
                        thumbnailImg: transformedSrc(
                           maxWidth: 100
                           maxHeight: 100
                           crop: CENTER
                        )
                        productImg: transformedSrc(
                           maxWidth: 800
                           maxHeight: 800
                           crop: CENTER
                        )
                        }
                     }
                  }
               }
               images(first: 50) {
                  edges {
                     node {
                        altText
                        thumbnailImg: transformedSrc(
                        maxWidth: 100
                        maxHeight: 100
                        crop: CENTER
                        )
                        productImg: transformedSrc(
                        maxWidth: 800
                        maxHeight: 800
                        crop: CENTER
                        )
                     }
                  }
               }
            }
         }
         }
      `;
  }

  render() {
    return (
      <Query query={this.createProductQuery(this.props.match.params.handle)}>
        {({ loading, err, data }) => {
          if (loading) {
            return <Loader />;
          } else if (err) {
            return <p>Error</p>;
          } else {
            const product = data.shop.productByHandle;
            return (
              <ProductView
                // eeData={this.state.eeData}
                handle={this.props.match.params.handle}
                product={product}
                addVariant={this.props.addVariant}
                onPathChange={this.props.onPathChange}
              />
            );
          }
        }}
      </Query>
    );
  }
}

export default Product;
