import React, { Component } from "react";
import HedgiesLoop from "../components/HedgiesLoop";

class BloqLoopHedgies extends Component {
  render() {
    const channel = this.props.channel;
    const bloq = this.props.data;
    return <HedgiesLoop channel={channel} data={bloq} />;
    //<HedgiesLoop data={bloq} />;
  }
}

export default BloqLoopHedgies;
