import React, { Component, Fragment } from "react";
import axios from "axios";
import PageMeta from "./PageMeta";
import Loading from "./Loader";
import HeroSlider from "./HeroSlider";
import BloqHandler from "../bloqs/BloqHandler";
import ScrollToTopOnMount from "./ScrollToTopOnMount";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageMeta: {},
      breadcrumbs: {},
      bloqs: {},
    };
  }

  handleMeta = (pageMetaValue, breadcrumbValue) => {
    this.setState({ pageMeta: pageMetaValue });
    this.setState({ breadcrumbs: breadcrumbValue });
  };

  componentDidMount() {
    var handle = this.props.match.params.handle;
    var pagesURI = "" + this.props.location.pathname;

    if (this.props.handle === "/homepage") {
      handle = this.props.handle;
      pagesURI = "" + this.props.handle;
    }

    axios.get(`http://admin.suncoasthedgehogs.com${pagesURI}`).then((page) => {
      this.setState({
        pageMeta: page.data.pageMeta[0],
        breadcrumbs: page.data.breadcrumbs,
        bloqs: page.data.blocks,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    var nextPagesURI = "" + nextProps.location.pathname;

    if (nextProps.handle === "/homepage") {
      nextPagesURI = "" + nextProps.handle;
    }

    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        pageMeta: {},
        breadcrumbs: [{}],
        bloqs: {},
      });
      axios
        .get(`http://admin.suncoasthedgehogs.com${nextPagesURI}`)
        .then((page) => {
          this.setState({
            pageMeta: page.data.pageMeta[0],
            breadcrumbs: page.data.breadcrumbs,
            bloqs: page.data.blocks,
          });
        });
    }
  }

  render() {
    const meta = this.state.pageMeta;
    const crumbs = this.state.breadcrumbs;
    const bloqs = this.state.bloqs;
    if (meta.title) {
      return (
        <Fragment>
          <ScrollToTopOnMount />
          <PageMeta
            path={this.props.location.pathname}
            meta={meta}
            crumbs={crumbs}
          />
          {crumbs.length === 1 ? <HeroSlider /> : null}
          {bloqs.map((bloq) => (
            <BloqHandler data={bloq} key={bloq.bloqId} />
          ))}
        </Fragment>
      );
    } else {
      return <Loading />;
    }
  }
}

export default Page;
