import React, { Component } from "react";

class VariantSelector extends Component {
  render() {
    var optionVisibility;
    if (this.props.option.values.length <= 1) {
      optionVisibility = "hidden";
    } else {
      optionVisibility = "";
    }

    var variantList = this.props.option.values.slice(1, 4);
    var variantOptions = [];
    if (this.props.soldStatus === "On Hold") {
      variantOptions = variantList.slice(2);
      //variantOptions = variantList;
    } else if (this.props.soldStatus === "Sold") {
      //variantOptions = variantList.slice(4);
      variantOptions = variantList;
    } else {
      variantOptions = variantList.slice(0, 2);
      //variantOptions = variantList;
    }

    console.log(variantOptions);

    return (
      <div className={`product__option__wrapper ${optionVisibility}`}>
        <select
          className="product__option"
          name={this.props.option.name}
          key={this.props.option.name}
          onChange={this.props.handleOptionChange}
        >
          {this.props.productType ? (
            <option value="" disabled selected>
              Purchase Options
            </option>
          ) : null}
          {variantOptions.map((value) => {
            return (
              <option value={value} key={`${this.props.option.name}-${value}`}>
                {value === "Buy Now" ? "Buy Now - Save $10" : null}
                {value === "Place Deposit" ? "Place Deposit - $75" : null}
                {value === "Pay Balance" ? "Pay Balance" : null}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

export default VariantSelector;

// import React, { Component } from "react";
// import { Select } from "semantic-ui-react";

// const VariantSelector = (props) => {
//   const {
//     option,
//     variants,
//     selectedOptions,
//     handleOptionChange,
//     totalInventory,
//   } = props;

//   console.log(option.values);

//   const VariantOptions = option.values.map((item) => {
//     const container = {};
//     container.key = `${option.name}-${item}`;
//     container.value = item;
//     container.text = item;
//     return container;
//   });

//   console.log(VariantOptions);

//   return (
//     <Select
//       className="Product__option"
//       name={option.name}
//       key={option.name}
//       placeholder="Purchase Options"
//       onChange={handleOptionChange}
//       options={VariantOptions}
//     />
//   );
// };

// export default VariantSelector;
