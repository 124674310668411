import React, { Component, Fragment } from "react";
import axios from "axios";
import PageHeader from "./PageHeader";
import PageCrumbs from "./PageCrumbs";
import HelmetMeta from "./HelmetMeta";

class PageMeta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageMeta: this.props.meta,
      breadcrumbs: this.props.crumbs,
    };
  }

  componentDidMount() {
    const handle = this.props.path;
    var pagesURI = "" + handle;

    if (handle === "/") {
      pagesURI = "" + handle + "homepage";
    }

    axios.get(`http://admin.suncoasthedgehogs.com${pagesURI}`).then((page) => {
      this.setState({
        pageMeta: page.data.pageMeta[0],
        breadcrumbs: page.data.breadcrumbs,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.path !== this.props.path) {
      const handle = this.props.path;
      var pagesURI = "" + handle;
      if (handle === "/") {
        pagesURI = "" + nextProps.path + "homepage";
      }
      this.setState({
        pageMeta: {},
        breadcrumbs: [{}],
      });
      axios
        .get(`http://admin.suncoasthedgehogs.com${pagesURI}`)
        .then((page) => {
          this.setState({
            pageMeta: page.data.pageMeta[0],
            breadcrumbs: page.data.breadcrumbs,
          });
        });
    }
  }

  render() {
    const meta = this.props.meta;
    const crumbs = this.props.crumbs;
    return (
      <Fragment>
        {crumbs ? (
          <Fragment>
            <HelmetMeta meta={this.state.pageMeta} />
            <PageHeader meta={meta} crumbs={crumbs} />
            <PageCrumbs data={crumbs} />
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

export default PageMeta;
