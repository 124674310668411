import React, { Fragment } from "react";
import { Button, Icon } from "semantic-ui-react";

const ProductButton = (props) => {
  const {
    selectedVariant,
    addVariant,
    productName,
    productType,
    totalInventory,
    soldStatus,
    status,
    tags,
  } = props;
  if (productType === "Hedgehog") {
    return (
      <div className="product-button">
        {selectedVariant.title !== "Default Title" &&
        selectedVariant.availableForSale &&
        soldStatus !== "Sold" ? (
          <Button
            primary
            fluid
            size="huge"
            onClick={() =>
              addVariant(selectedVariant.id, productType, productName)
            }
          >
            {soldStatus === "On Hold" ? (
              "Pay Balance"
            ) : (
              <Fragment>
                Add To Cart &nbsp; <Icon name="cart" />
              </Fragment>
            )}
          </Button>
        ) : (
          <Button secondary fluid disabled size="huge">
            {selectedVariant.title === "Default Title" ? (
              "Select Purchase Option"
            ) : (
              <Fragment>
                Sold{productType !== "Hedgehog" ? " Out" : null}
              </Fragment>
            )}
          </Button>
        )}
      </div>
    );
  } else {
    return (
      <div className="product-button">
        {productType === "Waitlist" ? "TEXTAREA" : null}
        {selectedVariant.availableForSale && soldStatus !== "Sold" ? (
          <Button
            primary
            fluid
            size="huge"
            onClick={() =>
              addVariant(selectedVariant.id, productType, productName)
            }
          >
            {soldStatus === "On Hold" ? (
              "Pay Balance"
            ) : (
              <Fragment>
                Add To Cart &nbsp; <Icon name="cart" />
              </Fragment>
            )}
          </Button>
        ) : (
          <Button secondary fluid disabled size="huge">
            Sold Out
          </Button>
        )}
      </div>
    );
  }
};

export default ProductButton;
