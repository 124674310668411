import React, { Component, Fragment } from "react";
import axios from "axios";
import PageMeta from "./PageMeta";
import Loading from "./Loader";
import { Link } from "react-router-dom";
import ConfirmCartPopup from "../components/ConfirmCartPopup";
import CartPageLineItem from "../components/CartPageLineItem";
import { Button, Container, Divider, Grid, Icon } from "semantic-ui-react";

class CartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageMeta: {},
      breadcrumbs: {},
      bloqs: {},
      confirmCartPopup: false,
    };
  }

  confirmCartPopupOpen = () => this.setState({ confirmCartPopup: true });
  confirmCartPopupClose = () => this.setState({ confirmCartPopup: false });

  componentDidMount() {
    axios.get(`http://admin.suncoasthedgehogs.com/cart`).then((page) => {
      this.setState({
        pageMeta: page.data.pageMeta[0],
        breadcrumbs: page.data.breadcrumbs,
        bloqs: page.data.blocks,
        confirmCartPopup: false,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.handle !== this.props.match.params.handle) {
      this.setState({
        pageMeta: {},
        breadcrumbs: [{}],
        bloqs: {},
        confirmCartPopup: false,
      });
      axios.get(`http://admin.suncoasthedgehogs.com/cart`).then((page) => {
        this.setState({
          pageMeta: page.data.pageMeta[0],
          breadcrumbs: page.data.breadcrumbs,
          bloqs: page.data.blocks,
          confirmCartPopup: false,
        });
      });
    }
  }

  render() {
    const meta = this.state.pageMeta;
    const crumbs = this.state.breadcrumbs;
    const {
      checkout,
      clearCart,
      removeVariant,
      updateVariantQuantity,
      pushVariantQuantity,
    } = this.props;
    const checkoutItems = this.props.checkout.lineItems;
    var hedgieCounter = 0;

    if (meta.title) {
      console.log(checkoutItems);
      return (
        <Fragment>
          <PageMeta path="/cart" meta={meta} crumbs={crumbs} />
          {checkoutItems.edges.length >= 1 ? (
            <Container style={{ margin: "3em auto" }}>
              <table className="cartTable">
                <tbody>
                  <tr>
                    <th>Item</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th className="columnBreak"></th>
                    <th>Total</th>
                    <th>
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                  {checkoutItems.edges.map((item, index) => {
                    if (item.node.variant.product.productType === "Hedgehog") {
                      hedgieCounter++;
                    }
                    return (
                      <CartPageLineItem
                        key={`line-item-${index}`}
                        item={item}
                        removeVariant={removeVariant}
                        updateVariantQuantity={updateVariantQuantity}
                      />
                    );
                  })}
                  <tr className="subtotal">
                    <th colSpan="3">Subtotal</th>
                    <th className="columnBreak"></th>
                    <td colSpan="2">${checkout.subtotalPriceV2.amount * 1}</td>
                  </tr>
                  <tr className="subtotal">
                    <td colSpan="3">
                      <Button secondary onClick={pushVariantQuantity}>
                        <Icon name="refresh" />
                        Update Cart
                      </Button>
                      <Button as={Link} secondary to="/supplies">
                        <Icon name="shopping basket" />
                        Continue Shopping
                      </Button>
                    </td>
                    <td className="columnBreak"></td>
                    <td colSpan="2">
                      {hedgieCounter >= 1 ? (
                        <Button
                          onClick={() => this.confirmCartPopupOpen()}
                          className="ui button"
                        >
                          Checkout
                        </Button>
                      ) : (
                        <a href={checkout.webUrl} className="ui button">
                          Checkout
                        </a>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <ConfirmCartPopup
                checkoutUrl={checkout.webUrl}
                open={this.state.confirmCartPopup}
                confirmClose={this.confirmCartPopupClose}
              />
              <Divider hidden />
              {/* <Grid>
                <Grid.Column textAlign="right">
                  <a href={checkout.webUrl} className="ui button">
                    Checkout
                  </a>
                </Grid.Column>
              </Grid> */}
            </Container>
          ) : (
            <Container style={{ margin: "3em auto" }}>
              <Grid stackable textAlign="center">
                <Grid.Column width="16">
                  <p>Your cart is empty.</p>
                  <Button as={Link} to="/hedgehogs-for-sale">
                    Browse Hedgehogs
                  </Button>
                  <Divider horizontal>Or</Divider>
                  <Button as={Link} to="/supplies">
                    Browse Supplies
                  </Button>
                </Grid.Column>
                <Grid.Column width="8"></Grid.Column>
                <Grid.Column width="8"></Grid.Column>
              </Grid>

              {/* <Grid>
                <Grid.Column width={16} textAlign="center">
                  <Button onClick={clearCart} secondary className="button">
                    Clear Cart
                  </Button>
                </Grid.Column>
              </Grid> */}
            </Container>
          )}
        </Fragment>
      );
    } else {
      return <Loading />;
    }
  }
}

export default CartPage;
