import React, { Component } from "react";
import VariantSelector from "../components/VariantSelector";
import ProductButton from "../components/ProductButton";
import { Grid, Icon, Segment } from "semantic-ui-react";

class CartFeatures extends Component {
  constructor() {
    super();
    this.state = {
      selectedOptions: {},
      selectedVariant: {
        priceV2: [{ amount: "0.00" }],
      },
      quantity: 1,
    };
    this.handleOptionChange = this.handleOptionChange.bind(this);
    //this.quantityChange = this.quantityChange.bind(this);
  }

  getInitialSelectedOptions(options) {
    if (options) {
      return options.reduce((acc, next) => {
        acc[next.name] = next.values[0];
        return acc;
      }, {});
    }
  }

  handleOptionChange(event) {
    const newSelectedOptions = Object.assign(
      { ...this.state.selectedOptions },
      { [event.target.name]: event.target.value }
    );
    this.setState({ selectedOptions: newSelectedOptions });
  }

  // quantityChange(event) {
  //   this.setState({ quantity: event.target.value });
  //   this.props.updateVariantQuantity(
  //     this.props.item.node.variant.id,
  //     event.target
  //   );
  // }

  getVariantForSelectedOptions(selectedOptions, variants) {
    return variants.find((variant) => {
      return variant.node.selectedOptions.every(
        (selectedOption) =>
          selectedOptions[selectedOption.name] === selectedOption.value
      );
    }).node;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.product.variants) {
      if (prevState.selectedOptions !== this.state.selectedOptions) {
        this.setState({
          selectedVariant: this.getVariantForSelectedOptions(
            this.state.selectedOptions,
            this.props.product.variants.edges
          ),
        });
      }
    }
  }

  componentDidMount() {
    const selectedOptions = this.getInitialSelectedOptions(
      this.props.product.options
    );
    this.setState({
      selectedOptions: selectedOptions,
    });
  }

  render() {
    const { product, addVariant, eeStatus } = this.props;
    const { selectedOptions, selectedVariant } = this.state;
    console.log(selectedVariant);

    if (product !== "") {
      var soldStatus = "";
      if (product.tags.includes("On Hold")) {
        soldStatus = "On Hold";
      }
      if (product.tags.includes("Sold")) {
        soldStatus = "Sold";
      }

      return (
        <div className="cartFeatures">
          <Grid>
            <Grid.Row verticalAlign="middle">
              <Grid.Column width={7}>
                <span
                  class={
                    product.productType === "Hedgehog"
                      ? "hedgiePrice"
                      : "productPrice"
                  }
                >
                  ${selectedVariant.price * 1}
                </span>
              </Grid.Column>
              <Grid.Column width={9}>
                {product.options.map((option, index) => {
                  return (
                    <VariantSelector
                      option={option}
                      variants={product.variants.edges}
                      selectedOptions={selectedOptions}
                      handleOptionChange={this.handleOptionChange}
                      productType={product.productType}
                      totalInventory={product.totalInventory}
                      soldStatus={soldStatus}
                    />
                  );
                })}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <ProductButton
                  selectedVariant={selectedVariant}
                  addVariant={addVariant}
                  totalInventory={product.totalInventory}
                  productName={product.title}
                  productType={product.productType}
                  soldStatus={soldStatus}
                  tags={product.tags}
                  status={eeStatus}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {product.productType !== "Hedgehog" &&
          selectedVariant.currentlyNotInStock ? (
            <Segment inverted color="yellow" className="warning">
              <Grid verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column
                    mobile={3}
                    tablet={5}
                    computer={5}
                    textAlign="center"
                  >
                    <Icon
                      name="exclamation"
                      circular
                      inverted
                      fitted
                      color="teal"
                      className="gradient-cool angled fluid"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={13} tablet={11} computer={11}>
                    <p>
                      <strong>NOTICE:</strong> This product is currently out of
                      stock. You can still order it, but it may take up to a
                      month to ship.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="cartFeatures">
          <p>This hedgie does NOT exist in Shopify :(</p>
        </div>
      );
    }
  }
}

export default CartFeatures;
