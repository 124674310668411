import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button, Item, Segment } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";

class WaitingLists extends Component {
  constructor() {
    super();
    this.state = {
      waitlists: {},
    };
  }

  componentDidMount() {
    axios
      .get(`http://admin.suncoasthedgehogs.com/eeData/waitlists`)
      .then((waitlists) => {
        this.setState({
          waitlists: waitlists.data,
        });
      });
  }

  render() {
    if (this.state.waitlists[0]) {
      if (this.state.waitlists[0].no_results) {
        return (
          <Segment inverted color="yellow">
            <p>{this.state.waitlists[0].no_results}</p>
          </Segment>
        );
      } else {
        return (
          <Item.Group>
            {this.state.waitlists.map((waitlist) => (
              <Item style={{ padding: "1em 0" }} key={waitlist.id}>
                <Item.Image src={waitlist.image} alt="test" />
                <Item.Content>
                  <Item.Header as="h4" className="productTitle upper">
                    {waitlist.name}
                  </Item.Header>
                  <Item.Meta className="productMeta">
                    <span className="sr-only">Price: ${waitlist.price}</span>
                    <span className="waitlist__availability">
                      {waitlist.totalSlots - waitlist.filledSlots} of{" "}
                      {waitlist.totalSlots} Available | *Limit{" "}
                      {waitlist.totalSlots} people
                    </span>
                  </Item.Meta>
                  <Item.Description>
                    {ReactHtmlParser(waitlist.description)}
                  </Item.Description>
                  <Item.Extra>
                    {waitlist.totalSlots - waitlist.filledSlots > 0 ? (
                      <Button
                        as={Link}
                        to={`/supplies/${waitlist.handle}`}
                        primary
                      >
                        Save My Spot
                      </Button>
                    ) : (
                      <Button
                        as={Link}
                        to={`/supplies/${waitlist.handle}`}
                        primary
                        disabled
                      >
                        Save My Spot
                      </Button>
                    )}
                    <Button
                      as={Link}
                      to={`/supplies/${waitlist.handle}`}
                      secondary
                    >
                      Waitlist Policies
                    </Button>
                  </Item.Extra>
                </Item.Content>
              </Item>
            ))}
          </Item.Group>
        );
      }
    } else {
      return <p>No data!</p>;
    }
  }
}

export default WaitingLists;
