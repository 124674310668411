import React, { Component, Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Placeholder,
  Card,
  Divider,
} from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";

class BlogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pageMeta: { headerBgColor: "gradient-warm", title: "Blog" },
      breadcrumbs: [{ content: "Home" }, { content: "Blog" }],
      featuredPosts: [{}],
      otherPosts: [{}, {}, {}, {}, {}, {}],
      paginate: {
        totalPages: 1,
        currentPage: 1,
        pages: [
          {
            type: "firstItem",
          },
          {
            type: "pageItem",
            number: 1,
          },
          {
            type: "lastItem",
          },
        ],
      },
    };
  }

  handlePathChange = () => {
    var crumbs = this.state.breadcrumbs;
    this.props.onPathChange(crumbs);
  };

  componentDidMount() {
    if (this.props.category) {
      const apiUrl = "http://admin.suncoasthedgehogs.com/eeData/blogSection/";
      const catUrl = this.props.match.params.handle;
      const pageUrl = this.props.match.params.page * 2 - 2;
      const getUrl = apiUrl + "category/" + catUrl + "/P" + pageUrl;
      axios.get(getUrl).then((blog) => {
        console.log(blog);
        this.setState({
          loading: false,
          pageMeta: blog.data.pageMeta,
          breadcrumbs: blog.data.breadcrumbs,
          featuredPosts: blog.data.featuredPosts,
          otherPosts: blog.data.otherPosts,
          paginate: blog.data.paginate[0],
        });
        console.log(getUrl);
        this.handlePathChange();
      });
    } else {
      const apiUrl = "http://admin.suncoasthedgehogs.com/eeData/blogSection/";
      const pageUrl = this.props.match.params.page * 2 - 2;
      const getUrl = apiUrl + "P" + pageUrl;

      axios.get(getUrl).then((blog) => {
        console.log(blog);
        this.setState({
          loading: false,
          pageMeta: blog.data.pageMeta,
          breadcrumbs: blog.data.breadcrumbs,
          featuredPosts: blog.data.featuredPosts,
          otherPosts: blog.data.otherPosts,
          paginate: blog.data.paginate[0],
        });
        console.log(getUrl);
        this.handlePathChange();
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.handle !== this.props.match.params.handle ||
      nextProps.match.params.page !== this.props.match.params.page
    ) {
      this.setState({
        loading: true,
        pageMeta: { headerBgColor: "gradient-warm", title: "Blog" },
        breadcrumbs: [{ content: "Home" }, { content: "Blog" }],
        featuredPosts: [{}],
        otherPosts: [{}, {}, {}, {}, {}, {}],
      });
      if (nextProps.category) {
        const apiUrl = "http://admin.suncoasthedgehogs.com/eeData/blogSection/";
        const catUrl = nextProps.match.params.handle;
        const pageUrl = nextProps.match.params.page * 2 - 2;
        const getUrl = apiUrl + "category/" + catUrl + "/P" + pageUrl;
        axios.get(getUrl).then((blog) => {
          console.log(blog);
          this.setState({
            loading: false,
            pageMeta: blog.data.pageMeta,
            breadcrumbs: blog.data.breadcrumbs,
            featuredPosts: blog.data.featuredPosts,
            otherPosts: blog.data.otherPosts,
            paginate: blog.data.paginate[0],
          });
          this.handlePathChange();
        });
      } else {
        const apiUrl = "http://admin.suncoasthedgehogs.com/eeData/blogSection/";
        const pageUrl = nextProps.match.params.page * 2 - 2;
        const getUrl = apiUrl + "P" + pageUrl;
        axios.get(getUrl).then((blog) => {
          console.log(blog);
          this.setState({
            loading: false,
            pageMeta: blog.data.pageMeta,
            breadcrumbs: blog.data.breadcrumbs,
            featuredPosts: blog.data.featuredPosts,
            otherPosts: blog.data.otherPosts,
            paginate: blog.data.paginate[0],
          });
          this.handlePathChange();
        });
      }
    }
  }

  render() {
    const loading = this.state.loading;

    const paginationLink = `/blog${
      this.props.category ? "/category/" + this.props.match.params.handle : ""
    }`;
    console.log(paginationLink);

    return (
      <Fragment>
        {this.state.featuredPosts.map((post) => (
          <div>
            {loading ? (
              <Grid>
                <Grid.Column width={9}>
                  <Placeholder>
                    <Placeholder.Image style={{ paddingTop: "56.25%" }} />
                  </Placeholder>
                </Grid.Column>
                <Grid.Column width={7} className="blog__article">
                  <Placeholder>
                    <Placeholder.Header>
                      <Placeholder.Line length="medium" />
                    </Placeholder.Header>
                    <Placeholder.Header>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Paragraph>
                  </Placeholder>
                  <Button disabled>Read More</Button>
                </Grid.Column>
              </Grid>
            ) : (
              <Grid className="blog__item">
                <Grid.Column width={9}>
                  <Image
                    src="https://placehold.it/500x300"
                    alt="Featured Image"
                  />
                </Grid.Column>
                <Grid.Column as="article" width={7} className="blog__article">
                  <Header as="h5" className="blog__preheader">
                    Featured Post
                  </Header>
                  <Header as="h2" className="blog__title">
                    {ReactHtmlParser(post.title)}
                  </Header>
                  <div className="blog__excerpt">
                    <p>
                      <span className="blog__date">{post.date}</span>{" "}
                      {post.excerpt}
                    </p>
                    <Button as={Link} to={`/blog/post/${post.slug}`}>
                      Read More
                    </Button>
                  </div>
                </Grid.Column>
              </Grid>
            )}
            <Divider hidden />
          </div>
        ))}
        <Grid>
          <Grid.Column width={16}>
            <Card.Group itemsPerRow={2} stackable>
              {this.state.otherPosts.map((post, index) => (
                <Card as="article" className="blog__item" key={post.id}>
                  {loading ? (
                    <Placeholder>
                      <Placeholder.Image style={{ paddingTop: "56.25%" }} />
                    </Placeholder>
                  ) : (
                    <Image src="https://placehold.it/500x300" />
                  )}
                  <Card.Content>
                    {loading ? (
                      <div>
                        <Divider hidden />
                        <Placeholder>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                        </Placeholder>
                      </div>
                    ) : (
                      <Card.Header as="h2" className="blog__title">
                        {ReactHtmlParser(post.title)}
                      </Card.Header>
                    )}
                  </Card.Content>
                  <Card.Content>
                    <Card.Description>
                      {loading ? (
                        <Placeholder>
                          <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      ) : (
                        <p>
                          <span className="blog__date">{post.date}</span>{" "}
                          {post.excerpt}
                        </p>
                      )}
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Button
                      primary
                      as={Link}
                      to={`/blog/post/${post.slug}`}
                      key={post.id}
                      disabled={loading}
                    >
                      Read More
                    </Button>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          </Grid.Column>
        </Grid>
        <Grid centered>
          <Grid.Column textAlign="center">
            {this.state.paginate.pages ? (
              <Menu compact>
                {this.state.paginate.pages.map((page) => {
                  var pageNumber;
                  var pageText;
                  var currentPage = this.state.paginate.currentPage;
                  if (page.type === "firstItem") {
                    pageNumber = 1;
                    pageText = "angle double left";
                  } else if (page.type === "lastItem") {
                    pageNumber = this.state.paginate.totalPages;
                    pageText = "angle double right";
                  } else if (page.type === "prevItem") {
                    pageNumber = currentPage - 1;
                    pageText = "angle left";
                  } else if (page.type === "nextItem") {
                    pageNumber = currentPage + 1;
                    pageText = "angle right";
                  } else {
                    pageNumber = page.number;
                  }
                  return (
                    <Menu.Item
                      as={Link}
                      to={`${paginationLink}/${
                        pageNumber > 1 ? pageNumber : ""
                      }`}
                      type={page.type}
                      className={
                        pageNumber === currentPage && page.type === "pageItem"
                          ? "active"
                          : null
                      }
                    >
                      {pageText ? <Icon name={pageText} /> : pageNumber}
                    </Menu.Item>
                  );
                })}
              </Menu>
            ) : null}
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}

export default BlogList;
