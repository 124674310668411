import React, { Component } from "react";
import axios from "axios";

import { Card, Segment } from "semantic-ui-react";
import HedgieCard from "./HedgieCard";
import PlaceholderHedgieCard from "./PlaceholderHedgieCard";

class HedgiesTeaser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hedgies: [],
      currentPage: 1,
    };
  }

  componentDidMount() {
    const status = this.props.status;
    //const currentPage = this.state.currentPage;
    const paginationSegment = this.state.currentPage * 4 - 4;

    axios
      .get(
        `http://admin.suncoasthedgehogs.com/eeData/hedgieTeaser/${status}/P${paginationSegment}`
      )
      .then((hedgies) => {
        this.setState({
          hedgies: hedgies.data,
        });
      });
  }

  render() {
    if (this.state.hedgies[0]) {
      if (this.state.hedgies[0].no_results) {
        return (
          <Segment inverted color="yellow">
            <p>{this.state.hedgies[0].no_results}</p>
          </Segment>
        );
      } else {
        return (
          <Card.Group
            itemsPerRow={4}
            doubling
            stackable
            className="hedgieTeaser"
          >
            {this.state.hedgies.map((hedgie) => (
              <HedgieCard hedgie={hedgie} key={hedgie.id} />
            ))}
          </Card.Group>
        );
      }
    } else {
      return (
        <Card.Group itemsPerRow={4} className="hedgieTeaser">
          <PlaceholderHedgieCard />
          <PlaceholderHedgieCard />
          <PlaceholderHedgieCard />
          <PlaceholderHedgieCard />
        </Card.Group>
      );
    }
  }
}

export default HedgiesTeaser;
