import React, { Component } from "react";
import axios from "axios";

class Pedigree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pedigreeData: {},
    };
  }

  componentDidMount() {
    // Original JavaScript code by Chirp Internet: www.chirp.com.au
    // Please acknowledge use of this code by including this header.

    function getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

    var adminToken = getCookie("exp_csrf_token");
    axios.defaults.xsrfCookieName = "exp_csrf_token";
    const slug = this.props.slug;

    axios
      .get(`http://admin.suncoasthedgehogs.com/eeData/pedigree/${slug}`, {
        responseType: "json",
        withCredentials: true,
      })
      .then((pedigreeData) => {
        this.setState({
          pedigreeData: pedigreeData.data[0],
        });
      });
  }

  render() {
    let build;
    if (this.state.pedigreeData.name) {
      const pedigreeData = this.state.pedigreeData;
      build = (
        <table>
          <tbody>
            <tr>
              <td rowSpan="15">{pedigreeData.name}</td>
            </tr>
            <tr>
              <td rowSpan="7">{pedigreeData.mother.name}</td>
            </tr>
            {/* <tr>
              <td rowSpan="3">{pedigreeData.mother.mother.name}</td>
            </tr>
            <tr>
              <td>{pedigreeData.mother.mother.mother.name}</td>
            </tr>
            <tr>
              <td>{pedigreeData.mother.mother.father.name}</td>
            </tr>
            <tr>
              <td rowSpan="3">{pedigreeData.mother.father.name}</td>
            </tr>
            <tr>
              <td>{pedigreeData.mother.father.mother.name}</td>
            </tr>
            <tr>
              <td>{pedigreeData.mother.father.father.name}</td>
            </tr> */}
            <tr>
              <td rowSpan="7">
                {pedigreeData.father.name
                  ? pedigreeData.father.name
                  : pedigreeData.father}
              </td>
            </tr>
            {/* <tr>
              <td rowSpan="3">{pedigreeData.father.mother.name}</td>
            </tr>
            <tr>
              <td>{pedigreeData.father.mother.mother.name}</td>
            </tr>
            <tr>
              <td>{pedigreeData.father.mother.father.name}</td>
            </tr>
            <tr>
              <td rowSpan="3">{pedigreeData.father.father.name}</td>
            </tr>
            <tr>
              <td>{pedigreeData.father.father.mother.name}</td>
            </tr>
            <tr>
              <td>{pedigreeData.father.father.father.name}</td>
            </tr> */}
          </tbody>
        </table>
      );
    } else {
      build = <div />;
    }
    return build;
  }
}

export default Pedigree;

// const Pedigree = props => {
//   const { eeData } = props;
//   return (

//   );
// };
