import React, { Component } from "react";
import axios from "axios";
import HedgiesTeaser from "./HedgiesTeaser";
import { Container, Divider, Grid, Header, Segment } from "semantic-ui-react";

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hedgies: [],
    };
  }

  componentDidMount() {
    axios
      .get("http://admin.suncoasthedgehogs.com/eeData/hedgies")
      .then((hedgies) => {
        this.setState({
          hedgies: hedgies.data,
        });
      });
  }

  render() {
    return (
      <Container>
        <Grid padded="vertically">
          <Grid.Row>
            <Grid.Column>
              <Segment basic>
                <Header as="h2" className="upper">
                  Babies for Sale
                </Header>
                <p>The hedgehogs below are looking for new homes.</p>
                <HedgiesTeaser status="for-sale" />
              </Segment>

              <Divider />

              <Segment basic>
                <Header as="h2" className="upper">
                  On Hold Hedgehogs
                </Header>
                <p>
                  The hedgehogs below have had deposits placed on them and are
                  on hold.
                </p>
                <HedgiesTeaser status="on-hold" />
              </Segment>

              <Divider />

              <Segment basic>
                <Header as="h2" className="upper">
                  Sold Hedgehogs
                </Header>
                <p>The hedgehogs below are sold and fully paid for.</p>
                <HedgiesTeaser status="sold" />
              </Segment>

              <Divider />

              <Segment basic>
                <Header as="h2" className="upper">
                  Picked Up Hedgehogs
                </Header>
                <p>The hedgehogs below have been picked up and taken home.</p>
                <HedgiesTeaser status="taken-home" />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default ProductList;
